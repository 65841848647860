

  @media only screen and (max-width : 767px) {


  	h1{
  		font-size: 28px;
  		line-height: 1.4;
  		margin-top: 60px;
  		span{
  			font-size: 28px;
  		}
  	}


  	.header{
  		padding-bottom: 100px;
  	}

.panel-title>a, .panel-title>a{
	font-size: 13px;
}

  	// .box-1{
  	// 	display: none;
  	// }

.box-2{
	padding: 15px;
}

h2{
	font-size: 18px;
}


.way li .circle{
	display: none;
}

.way_num{
	float: none;
	margin-bottom: 20px;
}
.way_text{
	max-width: 100%;
}
.way_step{
	padding: 15px;
	margin-bottom: 30px;
}

.some-text p{
	font-size: 14px;
	line-height: 1.4;
}

.price-table td{
	font-size: 14px;
}


.doc .doc_box{
	position: static;
	padding: 5px;
	-webkit-transform: none;
		   -moz-transform: none;
		    -ms-transform: none;
		     -o-transform: none;
		        transform: none;
}

.doc_inner{
	padding: 10px;
	font-size: 14px;
	
}


.doc_text{
	font-size: 14px;
}

.navbar-brand{
	img{
		height: 30px;
		width: auto;
	}
}


.numblock_text{
	font-size: 14px;
	line-height: 1.6;
}

.box{
	position: static;
	padding: 15px;
	-webkit-box-shadow: none;
	        box-shadow: none;
}

.price-table td{
	padding: 10px;
	text-transform: none;
}

.box-2_title{
	font-size: 22px;
	line-height: 1.6;
}

h2+.lead{
	font-size: 18px;
}

.section-form .btn{
	font-size: 18px;
}

.quote{
	margin-left: 0;
}

.section{
	padding-top: 40px;
	padding-bottom: 40px;
}

#googlemaps{
	display: none;
}

.contact-box{
	position: static;
	padding: 25px 15px;
	width: 100%;
	height: auto;
}
.contacts{
	height: auto;
	padding: 0;
}

.rule{
	font-size: 18px;
	span{
		font-size: 18px;
	}
}

.contact-title{
	font-size: 24px;
}

.contact-text{
	font-size: 16px;
}




    }

