.col-xxs-1,
.col-xxs-2,
.col-xxs-3,
.col-xxs-4,
.col-xxs-5,
.col-xxs-6,
.col-xxs-7,
.col-xxs-8,
.col-xxs-9,
.col-xxs-10,
.col-xxs-11,
.col-xxs-12 {
	min-height: 1px;
	padding-left: 5px;
	padding-right: 5px;
	position: relative
}

@media (max-width:580px) {
	.col-xxs-1,
	.col-xxs-2,
	.col-xxs-3,
	.col-xxs-4,
	.col-xxs-5,
	.col-xxs-6,
	.col-xxs-7,
	.col-xxs-8,
	.col-xxs-9,
	.col-xxs-10,
	.col-xxs-11 {
		float: left
	}

	.col-xxs-1 {
		width: 8.333333333333332%
	}

	.col-xxs-2 {
		width: 16.666666666666664%
	}

	.col-xxs-3 {
		width: 25%
	}

	.col-xxs-4 {
		width: 33.33333333333333%
	}

	.col-xxs-5 {
		width: 41.66666666666667%
	}

	.col-xxs-6 {
		width: 50%
	}

	.col-xxs-7 {
		width: 58.333333333333336%
	}

	.col-xxs-8 {
		width: 66.66666666666666%
	}

	.col-xxs-9 {
		width: 75%
	}

	.col-xxs-10 {
		width: 83.33333333333334%
	}

	.col-xxs-11 {
		width: 91.66666666666666%
	}

	.col-xxs-12 {
		width: 100%
	}

	.col-xxs-push-1 {
		left: 8.333333333333332%
	}

	.col-xxs-push-2 {
		left: 16.666666666666664%
	}

	.col-xxs-push-3 {
		left: 25%
	}

	.col-xss-push-4 {
		left: 33.33333333333333%
	}

	.col-xxs-push-5 {
		left: 41.66666666666667%
	}

	.col-xxs-push-6 {
		left: 50%
	}

	.col-xxs-push-7 {
		left: 58.333333333333336%
	}

	.col-xxs-push-8 {
		left: 66.66666666666666%
	}

	.col-xxs-push-9 {
		left: 75%
	}

	.col-xxs-push-10 {
		left: 83.33333333333334%
	}

	.col-xxs-push-11 {
		left: 91.66666666666666%
	}

	.col-xxs-pull-1 {
		right: 8.333333333333332%
	}

	.col-xxs-pull-2 {
		right: 16.666666666666664%
	}

	.col-xxs-pull-3 {
		right: 25%
	}

	.col-xxs-pull-4 {
		right: 33.33333333333333%
	}

	.col-xxs-pull-5 {
		right: 41.66666666666667%
	}

	.col-xxs-pull-6 {
		right: 50%
	}

	.col-xxs-pull-7 {
		right: 58.333333333333336%
	}

	.col-xxs-pull-8 {
		right: 66.66666666666666%
	}

	.col-xxs-pull-9 {
		right: 75%
	}

	.col-xxs-pull-10 {
		right: 83.33333333333334%
	}

	.col-xxs-pull-11 {
		right: 91.66666666666666%
	}

	.col-xxs-offset-1 {
		margin-left: 8.333333333333332%
	}

	.col-xxs-offset-2 {
		margin-left: 16.666666666666664%
	}

	.col-xxs-offset-3 {
		margin-left: 25%
	}

	.col-xxs-offset-4 {
		margin-left: 33.33333333333333%
	}

	.col-xxs-offset-5 {
		margin-left: 41.66666666666667%
	}

	.col-xxs-offset-6 {
		margin-left: 50%
	}

	.col-xxs-offset-7 {
		margin-left: 58.333333333333336%
	}

	.col-xxs-offset-8 {
		margin-left: 66.66666666666666%
	}

	.col-xxs-offset-9 {
		margin-left: 75%
	}

	.col-xxs-offset-10 {
		margin-left: 83.33333333333334%
	}

	.col-xxs-offset-11 {
		margin-left: 91.66666666666666%
	}

}

.text-left-not-xs,
.text-left-not-sm,
.text-left-not-md,
.text-left-not-lg {
	text-align: left
}

.text-center-not-xs,
.text-center-not-sm,
.text-center-not-md,
.text-center-not-lg {
	text-align: center
}

.text-right-not-xs,
.text-right-not-sm,
.text-right-not-md,
.text-right-not-lg {
	text-align: right
}

.text-justify-not-xs,
.text-justify-not-sm,
.text-justify-not-md,
.text-justify-not-lg {
	text-align: justify
}

@media (max-width:767px) {
	.text-left-not-xs,
	.text-center-not-xs,
	.text-right-not-xs,
	.text-justify-not-xs {
		text-align: inherit
	}

	.text-left-xs {
		text-align: left
	}

	.text-center-xs {
		text-align: center
	}

	.text-right-xs {
		text-align: right
	}

	.text-justify-xs {
		text-align: justify
	}

}

@media (min-width:768px) and (max-width:991px) {
	.text-left-not-sm,
	.text-center-not-sm,
	.text-right-not-sm,
	.text-justify-not-sm {
		text-align: inherit
	}

	.text-left-sm {
		text-align: left
	}

	.text-center-sm {
		text-align: center
	}

	.text-right-sm {
		text-align: right
	}

	.text-justify-sm {
		text-align: justify
	}

}

@media (min-width:992px) and (max-width:1199px) {
	.text-left-not-md,
	.text-center-not-md,
	.text-right-not-md,
	.text-justify-not-md {
		text-align: inherit
	}

	.text-left-md {
		text-align: left
	}

	.text-center-md {
		text-align: center
	}

	.text-right-md {
		text-align: right
	}

	.text-justify-md {
		text-align: justify
	}

}

@media (min-width:1200px) {
	.text-left-not-lg,
	.text-center-not-lg,
	.text-right-not-lg,
	.text-justify-not-lg {
		text-align: inherit
	}

	.text-left-lg {
		text-align: left
	}

	.text-center-lg {
		text-align: center
	}

	.text-right-lg {
		text-align: right
	}

	.text-justify-lg {
		text-align: justify
	}

}


.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}