/*-----------------------------------------------------------------------------------
  Template Name: GreenPark
-----------------------------------------------------------------------------------
  
  CSS INDEX
  ===================


	01. Вспомогательные классы
	02. Типография
	03. Header
	04. Footer
	05. Херо
	06. Карточка товара
	07. Карточка Новости
	08. Хлебные крошки
	09. Отзыв
	10. Форма
	11. Табы
	12. Фильтры
	13. Схема работы
	14. Галерея


-----------------------------------------------------------------------------------*/



@font-face {
    font-family: 'A Futurica Lt Thin';
    src: url('../fonts/AFuturicaLtThin.eot');
    src: url('../fonts/AFuturicaLtThin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AFuturicaLtThin.woff2') format('woff2'),
        url('../fonts/AFuturicaLtThin.woff') format('woff'),
        url('../fonts/AFuturicaLtThin.ttf') format('truetype'),
        url('../fonts/AFuturicaLtThin.svg#AFuturicaLtThin') format('svg');
    font-weight: 100;
    font-style: normal;
}





@import 'vars';
 @import 'bootstrap';
 @import 'bootstrap-compass';
 /*@import 'awesome/font-awesome';*/
 @import 'xss';
  @import 'default';
  @import 'slick';
  @import 'slick-theme';
  



/* 
   01. Вспомогательные классы
   ========================================================================== */
   


.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}





@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}


/* 
   02. Типография
   ========================================================================== */


h1{
	color: #fefefe;
font-family: "A Futurica Lt Thin";
font-size: 48px;
font-weight: bold;
line-height: 48px;
max-width: 475px;

margin: auto;
margin-top: 250px;
margin-bottom: 90px;

span{
	margin-top: 10px;
	display: inline-block;
	font-family: "A Futurica Lt Thin";
font-size: 60px;
font-weight: bold;
line-height: 48px;
}
}


h2{
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 30px;
font-weight: bold;
line-height: 24px;
text-transform: uppercase;
display: inline-block;
position: relative;
line-height: 1.4;
&:before{
	content: "";
	display: block;
	position: absolute;
	background-color: #00c0f8;
	height: 1px;
	width: 100%;
	top: -10px;
	right: 0;

}
&:after{
	content: "";
	display: block;
	position: absolute;
	background-color: #00c0f8;
	height: 1px;
	width: 33%;
	bottom: -10px;
	right: 0;

}

}




.navbar-nav>li>a{

opacity: 0.8;
color: #fefefe;
font-family: Helvetica;
font-size: 20px;
font-weight: 400;
line-height: 40px;
}



.btn{
	box-shadow: -2px 2px 8px 2px rgba(0, 192, 248, 0.35);
}

.btn-primary{
	color: #ffffff;
font-family: Helvetica;
font-size: 20px;
font-weight: 400;
}


/* 
   03. Header
   ========================================================================== */\
   

#header{
	background-image: url("../images/bg.png");
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	background-color: #FFF;
	background-position: center top;
}

.big-dot {
	background: url("../images/circle.png") no-repeat center;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	    display: block;
	    margin-bottom: 100px;
}

.header{
	padding-bottom: 280px;

}


.navbar{
	background: none;
	border: none;
}


.phone-top{

color: #ffffff;
font-family: Helvetica;
font-size: 20px;
font-weight: bold;
padding: 7px 0;
}


/* 
   04. Footer
   ========================================================================== */

/* 
   05. Херо
   ========================================================================== */

.section {}
.container {}
.row {}
.col-sm-4 {}
.numblock {
	text-align: center;
	background-position: left top;
	background-repeat: no-repeat;
	padding-top: 70px;
}
.numblock_text {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 18px;
font-weight: 400;
line-height: 24px;
}

/* 
   06. Карточка товара
   ========================================================================== */


.col-xs-12 {}
.box {}
.box-1 {
	margin-top: 40px;
max-width: 601px;
// height: 621px;
box-shadow: -6px 6px 9px 1px rgba(0, 0, 0, 0.13);
background-color: #ffffff;
padding: 40px;

position: relative;
right: -40px;
}
.clients {}
.cl-logo {
 width: 50%;
 float: left;
 text-align: center;
 img{
 	max-width: 100%;
 	height: auto;
 }

 &:nth-child(even){
 	margin-top: 45px;
 }
}
.box-2 {
	padding: 60px;
	padding-top: 120px;
max-width: 560px;
// height: 627px;
box-shadow: -5px 5px 15px 1px rgba(0, 0, 0, 0.05);
background-color: #f7fdff;

.btn{
	margin-top: 40px;
	margin-bottom: 40px;
}

}
.text-center {}
.box-2_title {
	margin-bottom: 60px;
	color: #333333;
font-family: "A Futurica Lt Thin";
font-size: 48px;
font-weight: bold;
line-height: 24px;
}
.box-carousel {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 20px;
font-weight: 400;
line-height: 24px;
}

.img-responsive{
	margin: auto;
}

.section{
	padding-bottom: 80px;
	padding-top: 80px;
}


h2{
	margin-bottom: 60px;
}


/* 
   07. before after
   ========================================================================== */
   
   .ba-slider {
    position: relative;
    overflow: hidden;
}
 
.ba-slider img {
    width: 100%;
    display:block;
    max-width:none;
}
 
.ba-slider .resize {
    position: absolute;
    top:0;
    left: 0;
    height: 100%;
    width: 50%;
    overflow: hidden;
}


.ba-slider .handle { /* Thin line seperator */
  position:absolute; 
  left:50%;
  top:0;
  bottom:0;
  width:4px;
  margin-left:-2px;
 
  background: rgba(0,0,0,.5);
  cursor: ew-resize;
}
 
.ba-slider .handle:after {  /* Big orange knob  */
    position: absolute;
    top: 50%;
    margin: -32px 0 0 -6px;
 
    content:'';
    color:white;
    font-weight:bold;
    font-size:36px;
    text-align:center;
    line-height:64px;
 
    // background: #ffb800; /* @orange */
    // border:1px solid #e6a600; /* darken(@orange, 5%) */
    // border-radius: 50%;
    // transition:all 0.3s ease;
    // box-shadow:
    //   0 2px 6px rgba(0,0,0,.3), 
    //   inset 0 2px 0 rgba(255,255,255,.5),
    //   inset 0 60px 50px -30px #ffd466; /* lighten(@orange, 20%)*/
      width: 15px;
      height: 62px;
      background: url("../images/toggle.png") no-repeat center;

}

// .ba-slider .handle.ba-draggable:after {
//     width: 48px;
//     height: 48px;
//     margin: -24px 0 0 -24px;
//     line-height:50px;
//     font-size:30px;
// }



.lead{
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 24px;
font-weight: bold;
margin-bottom: 60px;
}

h2 + .lead{
	margin-top: -40px;
}

/* 
   08. форма
   ========================================================================== */
   
   .section {}
.section-form {
	padding-bottom: 180px;
	background: url("../images/form-bg.png");
	
	background-repeat: no-repeat;
	background-position: center top;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	

	.btn{
		height: 50px;
		-webkit-border-radius: 0;
		        border-radius: 0;
	}

	.form-control {
		margin-right: 20px;
	border: 1px solid #ffffff;
	color: #FFF;
	-webkit-border-radius: 0;
	        border-radius: 0;
	background: none;
	-webkit-box-shadow: none;
	        box-shadow: none;
	height: 50px;

		}
		input::-webkit-input-placeholder , textarea::-webkit-input-placeholder {color:#fff !important;}
input::-moz-placeholder          , textarea::-moz-placeholder          {color:#fff !important;}/* Firefox 19+ */
input:-moz-placeholder           , textarea:-moz-placeholder           {color:#fff !important;}/* Firefox 18- */
input:-ms-input-placeholder      , textarea:-ms-input-placeholder      {color:#fff !important;}
}
.container {}
.row {}
.col-xs-12 {}
.text-center {}
.form-inline {}
.form-group {}
.sr-only {}

.btn {}
.btn-primary {}

/* 
   09. Почему
   ========================================================================== */


.section {}
.section-why {
	p{
		color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 20px;
font-weight: bold;
line-height: 24px;
	}
}
.container {}
.row {}
.col-xs-12 {}
.text-center {}
.col-md-5 {}
.img-responsive {}
.col-md-7 {}
.quote {
	
	border-left: 1px solid #000;
	

	padding: 15px;
	margin-left: 15px;
	

p{

font-size: 18px;
font-weight: 400;
font-style: italic;
line-height: 18px;
font-family: "A Futurica Lt Thin";
margin-bottom: 15px;

}
	
.name {
	
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 18px;
font-weight: bold;
line-height: 18px;
}

}
.small {}
.pull-right {}


.has-frame{
	position: relative;
	
	&:after{
		position: absolute;
		display: block;
		content: "";
		top: 20px;
		left: 20px;
		width: calc(100% - 20px);
		height: calc(100% - 20px);
		border: 1px solid #fff;
		background: red;
	}
}

/* 
   10.  каким образом
   ========================================================================== */

.section {}
.section-way {}
.container {}
.row {}
.col-xs-12 {}
.text-center {}

.way_step {
	max-width: 430px;
	box-shadow: -3px 3px 12px 1px rgba(0, 0, 0, 0.07);
background-color: #fafafa;
padding: 40px;
position: relative;
z-index: 15;



}
.way_num {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 24px;
font-weight: bold;
line-height: 24px;
text-transform: uppercase;
background-color: #d9f6ff;
padding: 20px;
display: inline-block;

// position: absolute;
// left: 15px;
// top: 15px;

margin: 0;
float: right;
}
.way_text {
	display: inline-block;
	max-width: 74%;
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 18px;
font-weight: 400;
line-height: 18px;
}



.way {
	list-style: none;
	
position: relative;

&:before{
	position: absolute;
	top: 25px;
	bottom: 18%;
	left: 0;
	right: 0;
	margin: auto;
	content: "";
	border: 1px dashed #000;
	width: 1px;

}

	li{
		width: 100%;
		position: relative;
		

		.circle{
			    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -14px;
    background-color: #999999;
    z-index: 1;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    width: 28px;
height: 28px;
box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.16);
background-color: #00c0f8;

&:before{
	content: "";
	width: 200px;
	position: absolute;
	top: 0;
	bottom: 0;
	// left: 0;
	margin: auto;
	border: 1px dashed #000;
	height: 1px;
	z-index: -1;
}

		}
	}

li:nth-child(even){
.circle:before{
	right: 100%;
	
}

}

	li:nth-child(odd){



text-align: right;

.way_num {
	float: left;
	margin-right: 15px;
	

}


.way_step{
	text-align: left;
	display: inline-block;
	}
}

}


li:nth-child(odd){
.circle:before{
	left: 28px;
	
}

}

/* 
   11. доктора
   ========================================================================== */
 .some-text{
margin: auto;

padding-top: 150px;
padding-bottom: 90px;

background: url("../images/some-bg.png") no-repeat center top;
-webkit-background-size: cover;
     -o-background-size: cover;
        background-size: cover;
     
     p{
     	max-width: 660px;
color: #ffffff;
font-family: "A Futurica Lt Thin";
font-size: 36px;
font-weight: bold;
line-height: 36px;
display: inline-block;
     }

 }

/* 
   11. доктора
   ========================================================================== */

.doc {
position: relative;
}




.doc_img {}
.doc_box {
	max-width: 655px;
	background: #FFF;
	padding: 35px;
}

.doc{

margin-bottom: 40px;

	.doc_img{

	}
	.doc_box{
		position: absolute;
		top: 50%;
		// bottom: 0;
		margin: auto;
		// right: 0;
		-webkit-transform: translateY(-50%);
		   -moz-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		     -o-transform: translateY(-50%);
		        transform: translateY(-50%);
		bottom: auto;
	}
}

.doc-left{
	.doc_box{
	right: 0;
	}
}
.doc-right{
.doc_box{
left: 0;
}

.doc_img{
text-align: right;
	}
}

.jumbotron{
	background-image: url('img/mans.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	height: 100vh;
	
	.introduction{
		  position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
	}
}

.doc_inner {

	border: 1px solid #363636;
padding: 40px 60px;
	div + div{
margin-top: 20px;
	}
}
.doc_name {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 26px;
font-weight: bold;
line-height: 24px;
}
.doc_what {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 20px;
font-weight: bold;
line-height: 24px;
}
.doc_text {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 18px;
font-weight: 400;
line-height: 20px;
}



/* 
   12. Таблица цен
   ========================================================================== */
   
.price-table{
 width: 100%;
 color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 30px;
font-weight: 400;
line-height: 30px;

 text-align: center;
 
 td{
 	padding: 25px;
 }

 tr+
 tr{
 	border-top: 1px solid #000;
 }

tr:hover{
	box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.15);
background-color: #ffffff;
}

}


/* 
   13. faq
   ========================================================================== */
   


.panel-heading {
  padding: 0;
	border:0;
}
.panel-title>a, .panel-title>a:active{
	display:block;
	padding:15px;
  color:#555;
  font-size:16px;
  font-weight:bold;
	text-transform:uppercase;
	letter-spacing:1px;
  word-spacing:3px;
	text-decoration:none;
}
.panel-heading  a:after {
    font-family: FontAwesome;
    content: "\f107";
   // float: right;
   display: inline-block;
   
   transition: all 0.5s;
}
.panel-heading.active a:after {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	transform: rotate(180deg);
	    
	     // content: "\f108";
} 


#accordion {
	.panel-default{
		border: none;
		-webkit-box-shadow: none;
		        box-shadow: none;
	}

	.panel-default>.panel-heading{
		background: none;
	}

	.panel {}
.panel-default {}
.panel-heading {
	border: none;
}
.panel-title {/* Style for "Сколько пр" */
color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 20px;
font-weight: bold;
line-height: 24px;}

.panel-body {
	border: none;
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 18px;
font-weight: 400;
line-height: 18px;
padding: 15px;
}
}

.row {}
.after-proc {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 18px;
font-weight: 400;
line-height: 18px;
margin-top: 60px;
}
.col-md-4 {}
.step {
	
	position: relative;
	
	&:after{
		position: absolute;
		content: "";
		display: block;
		background: url("../images/next.png") no-repeat center;
		width: 22px;
height: 41px;

top: 0;
bottom: 0;
right: -8px;
margin: auto;

	}

}
.step:last-child:after{
	display: none;
}
.step_text {
	text-align: center;
	margin-top: 15px;
	margin-bottom: 20px;
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 18px;
font-weight: 400;
line-height: 18px;
}


/* 
   14. Rule
   ========================================================================== */


.section {}
.section-rule {
	background: url("../images/stemcell.png") no-repeat center / cover;
	
}
.container {}
.row {}
.col-md-6 {}
.rule {
	color: #ffffff;
font-family: "A Futurica Lt Thin";
font-size: 40px;
font-weight: bold;
// line-height: 24px;
line-height: 1.2;
/* Text style for "" */
font-size: 36px;
/* Text style for "PicoSure" */

span{

font-size: 40px;
}
}
/* 
   14. Отзывы
   ========================================================================== */



.feed-carousel {
	

	.media {}
.pull-left {}
.media-object {}
.img-circle {
	max-width: 220px;
	width: 100%;
	margin-right: 25px;
	height: auto;
}
.media-body {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 20px;
font-weight: 400;
line-height: 24px;
}
.feed-name {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 20px;
font-weight: bold;
line-height: 24px;
}

hr{
	border-color: #000;
	max-width: 220px;
}
}



.slick-next, .slick-prev{
	width: 54px;
height: 54px;
background-color: rgba(0, 192, 248, 0.2) !important;
opacity: 0.1;
-webkit-border-radius: 54px;
        border-radius: 54px;
}


.slick-prev{
	left: -74px;
}

.slick-next{
	right: -74px;
}

.slick-arrow{
	opacity: 1;
}

.slick-dots li button:before{
	border: 1px solid #707070;
 background-color: #fff;
-webkit-border-radius: 50px;
        border-radius: 50px;
height: 19px;
width: 19px;
font-size: 1px;
text-indent: -9999px;
}


.slick-dots li.slick-active button:before{
 background-color: #bdf0ff;
 border: none;
height: 20px;
width: 20px;

}


/* contacts */


.contacts{
	position: relative;
	height: 550px;
}

#googlemaps { 

  min-height: 500px; 
  width: 100%; 
  position:absolute; 
  top: 30px; 
  
  left: 0; 
  z-index: 0; /* Set z-index to 0 as it will be on a layer below the contact form */
}



/* footer */
.footer{
	background-color: #141c1f;
}

.copyright{
	color: #FFF;
	margin-bottom: 20px;
}

.contact-box {
	position: absolute;
	height: 550px;
	top: 0;
	bottom: 0;
	right: 0;
	width: 45%;
	z-index: 20;
	background: #FFF;
	padding: 115px;
	padding-right: 0;
box-shadow: -2px 2px 8px 2px rgba(0, 0, 0, 0.16);
background-color: #ffffff;

}
.contact-title {
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 30px;
font-weight: bold;
line-height: 36px;
margin-bottom: 20px;
}
.contact-text {
	
color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 20px;
font-weight: bold;

line-height: 2;

span{
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 24px;
font-weight: bold;
line-height: 30px;
border-bottom: 1px solid #000;
}

}

.btn{
	-webkit-border-radius: 0;
	        border-radius: 0;
}

/* form 2 section */

.section {}
.section-form2 {
	margin-top: -20px;

	background: url("../images/bgfooter.png") no-repeat center / cover;
	

.white-form {
	box-shadow: -2px 2px 8px 2px rgba(0, 0, 0, 0.08);
background-color: #ffffff;
padding: 40px 30px;
max-width: 362px;
margin: auto;
}
.form-group {}
.form-control {
	border: 1px solid #000;
	color: #000000;
font-family: "A Futurica Lt Thin";
font-size: 16px;
font-weight: 400;
padding-left: 30px;
height: 50px;
-webkit-border-radius: 0;
        border-radius: 0;
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.btn {
	box-shadow: -2px 2px 9px 1px rgba(0, 192, 248, 0.3);
height: 50px;
-webkit-border-radius: 0;
        border-radius: 0;

width: 100%;
color: #fefefe;
font-family: Helvetica;
font-size: 18px;
font-weight: 400;
}

input::-webkit-input-placeholder , textarea::-webkit-input-placeholder {color:#000 !important;}
input::-moz-placeholder          , textarea::-moz-placeholder          {color:#000 !important;}/* Firefox 19+ */
input:-moz-placeholder           , textarea:-moz-placeholder           {color:#000 !important;}/* Firefox 18- */
input:-ms-input-placeholder      , textarea:-ms-input-placeholder      {color:#000 !important;}

}


  @media only screen and (max-width : 991px) {

  	.form-inline .form-group{
  		display: block;
  		max-width: 280px;
  		margin: auto;
  		margin-bottom: 15px;
  		

  	}

  	.box-1{
  		max-width: 100%;
  	}

  	.doc .doc_box{
  		bottom: -40px;
  		top: auto;
  		-webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  	}

  	.form-inline .form-control{
  		width: 100%;
  	}


.way_step{
	padding: 20px;
	
	margin-bottom: 30px;

    }


    .price-table td{
    	font-size: 18px;
    }

    .navbar-brand>img{
    	max-height: 55px;
    	width: auto;
    }

.navbar-nav>li>a{
	font-size: 14px;
}

}

.navbar-default .navbar-collapse, .navbar-default .navbar-form{
	border: none !important;
}

.black{
	background: rgba(0, 0, 0, 0.7);
	padding-bottom: 20px;
}

.is-sticky{
	z-index: 99;
	position: relative;
	
}

@import 'responsive';